import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { RouteProps } from "react-router-dom";
import "./EmailConfirmationComplete.css";

const EmailConfirmationComplete: FC<RouteProps> = () => {
    return (
        <>
            <Helmet>
                <title>パスワード設定完了 | Aitem</title>
                <meta name='description' content='『パスワード設定完了』ページです。' />
            </Helmet>

            <div className='EmailConfirmationComplete l-wraper'>
                <div className='l-container'>
                    <div className='c-logo'>
                        <img alt='Aitem' width='100' height='34' src={`${process.env.PUBLIC_URL}/images/aitem_logo.png`} />
                    </div>
                    <div className='l-body'>
                        <h2 className='c-heading'>パスワード設定が完了</h2>
                        <p className='EmailConfirmationComplete__Text'>
                            <span>下記リンクよりアプリをダウンロード後、</span>
                            <span>ログインしてください。</span>
                        </p>
                        <div className='EmailConfirmationComplete__LinkContainer'>
                            <a
                                className='EmailConfirmationComplete__LinkApple'
                                href='http://' // TODO: URL
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img className='EmailConfirmationComplete__Image' src={`${process.env.PUBLIC_URL}/images/apple-store-badge.svg`} alt='AppleStoreでアプリをダウンロード' width='109' height='40' />
                            </a>
                            <a
                                className='EmailConfirmationComplete__LinkAndroid'
                                href='http://' // TODO: URL
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img className='EmailConfirmationComplete__Image' src={`${process.env.PUBLIC_URL}/images/google-play-badge.png`} alt='GooglePlayでアプリをダウンロード' width='646' height='250' />
                            </a>
                        </div>
                    </div>
                    <ul className='EmailConfirmationComplete__List'>
                        <li className='EmailConfirmationComplete__ListItem'>アプリダウンロード後、招待されたメールアドレスと設定したパスワードでログインできます。</li>
                        <li className='EmailConfirmationComplete__ListItem'>
                            {/*  // TODO: タイトル */}
                            アプリダウンロードのURLは『タイトル：〇〇』でお送りしたメールにも記載があります。
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export { EmailConfirmationComplete };
