import { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { RouteProps, useNavigate, useParams } from "react-router-dom";
import axios, { isAxiosError } from "axios";
import "./EmailConfirmationInput.css";

const EmailConfirmationInput: FC<RouteProps> = () => {
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowPasswordCheck, setIsShowPasswordCheck] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const { signature } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (passwordCheck.length && password === passwordCheck) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [password, passwordCheck]);

    const handleOnClick = async (): Promise<void> => {
        setIsProcess(true);
        setIsShowPassword(false);
        setIsShowPasswordCheck(false);

        const params = {
            password1: password,
            password2: passwordCheck,
            signature: signature,
        };
        try {
            await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/confirmStudent`, params, {
                timeout: 10000,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            navigate("/registration/complete");
        } catch (err) {
            if (isAxiosError(err)) {
                const data = err.response?.data;
                if (data !== undefined) {
                    switch (data.code) {
                        case "1001":
                            window.alert("許可されていない操作です");
                            break;
                        case "1002":
                            window.alert("パスワードは6文字以上で入力してください。");
                            break;
                        case "1003":
                            window.alert("パスワードが一致しません。");
                            break;
                        case "1004":
                            window.alert("不正な操作が行われました。");
                            break;
                        case "1005":
                            window.alert("既に登録されています。");
                            break;
                        case "1006":
                            window.alert("有効期限が切れています。");
                            break;
                        case "9000":
                            window.alert("エラーが発生しました。しばらくしてからもう一度お試しください。");
                            break;
                    }
                } else {
                    window.alert("エラーが発生しました。しばらくしてからもう一度お試しください。");
                }
            } else {
                window.alert("エラーが発生しました。しばらくしてからもう一度お試しください。");
            }
            setPassword("");
            setPasswordCheck("");
            setIsProcess(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>パスワード設定 | Aitem</title>
                <meta name='description' content='『パスワード設定』ページです。' />
            </Helmet>

            <div className='EmailConfirmationInput l-wraper'>
                <div className='l-container'>
                    <div className='c-logo'>
                        <img alt='Aitem' width='100' height='34' src={`${process.env.PUBLIC_URL}/images/aitem_logo.png`} />
                    </div>
                    <form className='l-body'>
                        <h2 className='c-heading'>パスワード設定</h2>
                        <p className='EmailConfirmationInput__Text'>ログインパスワードの設定をお願いします。</p>
                        <div className='EmailConfirmationInput__FormWrap'>
                            <label className='EmailConfirmationInput__Label' htmlFor='password'>
                                パスワード
                            </label>
                            <div className='EmailConfirmationInput__FormBody'>
                                <input
                                    className='EmailConfirmationInput__Input c-input'
                                    type={isShowPassword ? "input" : "password"}
                                    name='password'
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                                <div className='EmailConfirmationInput__ToggleContainer'>
                                    <input className='EmailConfirmationInput__ToggleInput u-visibilityHidden' id='show-password' type='checkbox' checked={isShowPassword} onChange={() => setIsShowPassword(!isShowPassword)} />
                                    <label className='EmailConfirmationInput__ToggleLabel' htmlFor='show-password'>
                                        <div className='EmailConfirmationInput__ToggleIcon'>{isShowPassword ? <img alt='' width='24' height='24' src={`${process.env.PUBLIC_URL}/images/icon-visibility-off.svg`} /> : <img alt='' width='24' height='24' src={`${process.env.PUBLIC_URL}/images/icon-visibility.svg`} />}</div>
                                        <span className='u-visibilityHidden'>パスワードを表示する</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='EmailConfirmationInput__FormWrap'>
                            <label className='EmailConfirmationInput__Label' htmlFor='passwordCheck'>
                                パスワード（確認用）
                            </label>
                            <div className='EmailConfirmationInput__FormBody'>
                                <input
                                    className='EmailConfirmationInput__Input c-input'
                                    type={isShowPasswordCheck ? "input" : "password"}
                                    name='passwordCheck'
                                    value={passwordCheck}
                                    onChange={(event) => {
                                        setPasswordCheck(event.target.value);
                                    }}
                                />
                                <div className='EmailConfirmationInput__ToggleContainer'>
                                    <input className='EmailConfirmationInput__ToggleInput u-visibilityHidden' id='show-password-check' type='checkbox' checked={isShowPasswordCheck} onChange={() => setIsShowPasswordCheck(!isShowPasswordCheck)} />
                                    <label className='EmailConfirmationInput__ToggleLabel' htmlFor='show-password-check'>
                                        <div className='EmailConfirmationInput__ToggleIcon'>{isShowPasswordCheck ? <img alt='' width='24' height='24' src={`${process.env.PUBLIC_URL}/images/icon-visibility-off.svg`} /> : <img alt='' width='24' height='24' src={`${process.env.PUBLIC_URL}/images/icon-visibility.svg`} />}</div>
                                        <span className='u-visibilityHidden'>パスワードを表示する</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <button className={`EmailConfirmationInput__Button ${isProcess && "--is-process"}`} type='button' disabled={!isValid || isProcess} onClick={handleOnClick}>
                            {isProcess ? (
                                <div className='ball-pulse'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : (
                                "設定する"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export { EmailConfirmationInput };
